.container {
    margin: 8px 0 0;
    font-size: 14px;
    color: var(--color-grey-mild);
}

.strength {
    display: flex;
    align-items: center;

    .title {
        margin: 0 16px 0 0;
    }

    .text {
        width: 90px;
        font-weight: bold;
        margin: 0 16px 0 0;
    }

    .progress {
        flex: 1;
        display: flex;
    }

    .progress-item {
        flex: 1;
        height: 4px;
        border-radius: 3px;
        background: #ddd;

        &:not(:last-child) {
            margin: 0 8px 0 0;
        }
    }

    &.very-weak {
        .text {
            color: var(--color-red);
        }

        .progress-item:nth-child(-n+1) {
            background: var(--color-red);
        }
    }

    &.weak {
        .text {
            color: var(--color-red);
        }

        .progress-item:nth-child(-n+2) {
            background: var(--color-red);
        }
    }

    &.medium {
        .text {
            color: var(--color-yellow);
        }

        .progress-item:nth-child(-n+3) {
            background: var(--color-yellow);
        }
    }

    &.strong {
        .text {
            color: var(--color-green);
        }

        .progress-item:nth-child(-n+4) {
            background: var(--color-green);
        }
    }

    &.very-strong {
        .text {
            color: var(--color-green);
        }

        .progress-item:nth-child(-n+5) {
            background: var(--color-green);
        }
    }
}

.requirements {
    margin: 8px 0 0;

    .list {
        margin: 4px 0 0;

        .list-item {
            display: flex;
            align-items: center;
            line-height: 16px;

            .icon {
                width: 12px;
                height: 12px;
                color: var(--color-grey-sand);
            }

            .text {
                margin: 0 0 0 8px;
            }

            &.fulfilled {
                .icon {
                    color: var(--color-green);
                }
            }

            &:not(:first-child) {
                margin: 4px 0 0;
            }
        }
    }
}

@media(max-width: 768px) {
    .strength {
        flex-wrap: wrap;

        .progress {
            margin: 8px 0 0;
            width: 100%;
            flex: unset;
        }
    }

    .requirements {
        margin: 16px 0 0;
    }
}
