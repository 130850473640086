.container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 4px;
    background: var(--color-blue);
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    transition: all ease .2s;

    &:hover {
        background: var(--color-blue-mild);

        .pop-on-hover {
            opacity: 1;
        }
    }

    &.is-loading {
        pointer-events: none;
    }

    &.icon-after {
        .holder {
            flex-direction: row-reverse;
            
            .icon + .label {
                margin: 0 8px 0 0;
            }
        }
    }

    .holder {
        display: flex;
        align-items: center;
    }

    .icon {
        width: 18px;
        height: 18px;
    }

    .icon + .label {
        margin: 0 0 0 8px;
    }

    .loading {
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: 4px;
        top: -1px;
        left: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-blue);
    }

    .popover {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 4px;
        background: #333;
        color: var(--color-white);
        border-radius: 0 0 4px 4px;
        font-size: 12px;
        text-align: center;
    }

    .pop-on-hover {
        position: absolute;
        top: 100%;
        margin: 8px 0 0;
        left: 0;
        width: max-content;
        max-width: 200%;
        padding: 4px;
        background: #3339;
        color: var(--color-white);
        border-radius: 4px;
        font-size: 12px;
        z-index: 10;
        transition: all ease .2s;
        pointer-events: none;
        opacity: 0;

        &::after {
            content: '';
            position: absolute;
            top: -12px;
            left: 8px;
            border: 6px solid transparent;
            border-bottom: 6px solid #3338;
        }
    }

    &.theme-negative {
        background: transparent;
        color: #286090;
        border: 1px solid transparent;

        &:hover {
            background: #28609011;
        }
    }

    &.theme-yellow {
        background: var(--color-yellow);
        color: var(--color-yellow-dark);

        &:hover {
            opacity: .9;
        }
    }

    &.red {
        background: #d9534f;

        &:hover {
            background: #c9302c;
        }

        &.is-loading {
            .loading {
                background: #d9534f;
            }
        }
    }

    &.theme-sec {
        background: var(--color-white);
        border: 1px solid #337ab7;
        color: #337ab7;

        &:hover {
            background: #cfe2ff44;
        }

        &.disabled {
            background: var(--color-white);
            opacity: .5;
        }

        &.red {
            border-color: var(--color-red);
            color: var(--color-red);

            &:hover {
                background: var(--color-red-light);
            }
        }

        &.is-loading {
            .loading {
                background: var(--color-white);
                border: 1px solid #337ab7;
            }
        }
    }

    &.theme-orange {
        background: var(--color-orange);
        color: var(--color-white);
        border: 1px solid var(--color-orange);
    }

    &.theme-orange-sec {
        background: var(--color-white);
        color: var(--color-orange);
        border: 1px solid var(--color-orange);

        &:not(.disabled):hover {
            opacity: .8;
        }
    }

    &.theme-green {
        background: var(--color-green);
        border: 1px solid var(--color-green);

        &:not(.disabled):hover {
            opacity: .9;
        }

        &.disabled {
            background: var(--color-green);
            opacity: .7;
            pointer-events: none;
        }
    }

    &.disabled {
        background: #ccc;
        cursor: default;
        pointer-events: none;
    }

    &.compact {
        padding: 8px;
        font-size: 12px;
    }
}

@media(max-width: 768px) {
    .container {
        &.collapse {
            .label {
                display: none;
            }
        }
    }
}
