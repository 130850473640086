.container {
    display: flex;

    .left,
    .right {
        flex: 1;
        display: flex;

        & > * {
            &:not(:last-child) {
                margin-right: 12px;
            }
        }
    }

    .right {
        justify-content: flex-end;
    }
}

@media(max-width: 768px) {
    .container {
        &.wrap {
            display: block;
            
            .left {
                justify-content: flex-end;
            }

            .left + .right {
                margin: 12px 0 0;
            }
        }
    }
}
