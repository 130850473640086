.table {
    width: 100%;

    &.disabled {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #fffa;
            z-index: 1;
        }
    }

    &.is-loading {
        @extend .disabled;

        .loading {
            display: flex;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            justify-content: center;
            align-items: center;
            color: var(--color-blue);
            z-index: 2;
        }
    }

    .loading {
        display: none;
    }

    .table-holder {
        width: 100%;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    thead {
        tr {
            text-transform: uppercase;
            font-weight: bold;
            background: #fff;
            border-bottom: 1px solid #aaa;
            font-size: 14px;
            color: #2f3e52;
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background: #f9f9f9;
            }
    
            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }
    
            &:hover {
                background: #eee;
                border-color: #eee;
            }
    
            &.link {
                cursor: pointer;
            }
    
            color: #666;
            font-size: 14px;
            overflow-y: visible;
        }
    }

    td {
        padding: 12px 8px;
        border-collapse: collapse;
        white-space: nowrap;
        overflow: visible;

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }

        &.clamp {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@media(max-width: 768px) {
    .table {
        overflow-x: scroll;
        
        td {
            &.clamp {
                max-width: 250px;
            }
        }
    }
}
