.container {
    width: 16px;
    height: 16px;
    max-width: 100%;
    max-height: 100%;

    &.custom-size {
        width: 100%;
        height: 100%;
    }

    &.thin {
        & svg > g {
            stroke-width: 1;
        }
    }

    &.thick {
        & svg > g {
            stroke-width: 3;
        }
    }

    & svg {
        width: 100%;
        height: 100%;
        display: block;
    }

    &.inline {
        display: inline-flex;
    }

    &.clickable {
        cursor: pointer;
    }
}
