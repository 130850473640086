.form-row {
    display: flex;

    & > * {
        flex: 1;
    }

    & > *:not(:last-child) {
        margin: 0 16px 0 0;
    }

    &:not(:first-child) {
        margin: 24px 0 0;
    }

    & > .form-item {
        margin-top: 0 !important;

        &:not(:last-child) {
            margin-right: 16px;
        }
    }
}

.form-item {
    flex: 3;

    &.size-smaller {flex: 1;}
    &.size-small {flex: 2;}
    &.size-large {flex: 4;}
    &.size-larger {flex: 5;}

    &.children-as-value {
        .form-item-content {
            @extend .form-item-value;
        }
    }

    .form-item-top {
        display: flex;
        align-items: center;
        color: var(--color-blue-dark);

        .form-item-title {
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 4px;
        }
    }

    .form-item-obs {
        font-size: 12px;
        color: var(--color-grey-light);
        font-weight: bold;

        a {
            color: var(--link);
            border-bottom: 1px dotted;

            &:hover {
                opacity: .7;
            }
        }

        &.form-item-obs-right {
            display: flex;
            justify-content: flex-end;
        }
    }

    .form-item-content {
        margin: 8px 0 0;
    }

    .form-item-value {
        padding: 12px;
        border-radius: 4px;
        background: var(--color-grey-plain);
    }

    &:not(:first-child) {
        margin: 8px 0 0;
    }

    select {
        height: 40px;
    }
}

.form-group {
    border: 1px solid var(--color-grey-sand);
    border-radius: 4px;
    padding: 24px 16px 16px;
    position: relative;

    .form-group-label {
        position: absolute;
        top: -8px;
        left: 12px;
        padding: 0 4px;
        background: var(--color-white);
        font-size: 14px;
    }
}

.form-buttons {
    margin: 24px 0 0;
    display: flex;
    justify-content: flex-end;

    & > div:not(:last-child) {
        margin: 0 16px 0 0;
    }
}

@media(max-width: 768px) {
    .form-row {
        display: block;
        margin-top: 0 !important;;

        & > .form-item {
            margin-top: 16px !important;
            margin-right: 0 !important;
        }
    }
}
