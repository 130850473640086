.container {
    padding: 24px;
    width: 350px;

    &.align-left {
        .title {
            text-align: left;
        }

        .text {
            text-align: left;
        }
    }

    &.button-centered {
        .buttons {
            justify-content: center;
        }
    }

    &.is-loading {
        .top {
            .close {
                cursor: default;
                opacity: .2;
            }
        }
    }
}

.top {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 -12px 0;

    .close {
        width: 16px;
        height: 16px;
        color: var(--grey-dim);
        cursor: pointer;
    }
}

.icon {
    margin: 16px auto 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 2px solid var(--color-grey-light);
    background: var(--color-grey-sand);
    color: var(--color-grey-light);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-holder {
        width: 28px;
        height: 28px;
    }
}

.container.theme-green .icon {
    border-color: var(--color-green);
    background: var(--color-green-light);
    color: var(--color-green);
}

.container.theme-yellow .icon {
    border-color: var(--color-yellow);
    background: var(--color-yellow-light);
    color: var(--color-yellow);
}

.container.theme-red .icon {
    border-color: var(--color-red);
    background: var(--color-red-light);
    color: var(--color-red);
}

.container.theme-blue .icon {
    border-color: var(--color-blue);
    background: var(--color-blue-light);
    color: var(--color-white);
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin: 24px 0 0;
    text-align: center;
    color: var(--color-grey-dark);
    line-height: 24px;
}

.text {
    margin: 20px 0 0;
    font-size: 14px;
    letter-spacing: .25px;
    line-height: 20px;
    text-align: center;
    color: var(--grey-dim);
}

.details {
    margin: 12px;
    font-size: 12px;
    text-align: center;
    color: var(--grey-dim);
}

.buttons {
    margin: 40px 0 0;
    display: flex;
    justify-content: flex-end;
    
    .buttons-holder {
        display: flex;
        justify-content: flex-end;
        position: relative;
    }

    .buttons-item {
        &:not(:first-child) {
            margin: 0 0 0 16px;
        }
    }

    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--color-white);
        color: #337ab7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width: 767px) {
    .container {
        padding: 16px;
        width: unset;
    }
}
