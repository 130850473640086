.container {
    background: var(--color-grey-platinum);
    padding: 12px 0 12px 16px;
    border-radius: 4px;
    font-size: 14px;
    transition: all ease .2s;
    opacity: 0;
    animation-name: show;
    animation-duration: .5s;
    animation-fill-mode: forwards;

    &:not(:first-child) {
        margin-top: 8px;
    }

    &.is-closing {
        animation-name: close;
        animation-duration: .3s;
        animation-fill-mode: forwards;
    }

    &.clickable {
        cursor: pointer;
    }
    
    .content {
        display: flex;
    }

    .icon {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
    }

    .main {
        flex: 1;
        margin: 0 4px 0 0;

        .text {
            font-weight: bold;
        }

        .details {
            margin: 4px 0 0;
        }
    }

    .close {
        width: 16px;
        padding: 4px 8px 4px 4px;
        box-sizing: content-box;
        margin: -4px 0 0 0;
        transition: all ease .2s;
        cursor: pointer;

        &:hover {
            opacity: .7;
        }
    }

    &.theme-red {
        background: var(--color-red);
        color: var(--color-white);
    }

    &.theme-yellow {
        background: var(--color-yellow);
        color: var(--color-yellow-dark);
    }

    &.theme-green {
        background: var(--color-green);
        color: var(--color-white);
    }

    &.theme-blue {
        background: var(--color-blue);
        color: var(--color-white);
    }

    &.theme-indigo {
        background: var(--color-indigo);
        color: var(--color-white);
    }
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes close {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
