.container {
    position: relative;
    z-index: 1;

    &.refreshing {
        &::after {
            content: '';
            position: absolute;
            width: 100%; height: 100%;
            top: 0; left: 0;
            background: rgba(255, 255, 255, .5);
            z-index: 100;
        }
    }

    &.disabled {
        &::after {
            content: '';
            position: absolute;
            width: 100%; height: 100%;
            top: 0; left: 0;
            background: rgba(255, 255, 255, .5);
            z-index: 100;
        }
    }
}

.loading {
    color: var(--color-main);
}
