.backdrop {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 100;

    &.open {
        display: flex;
        animation: show .3s ease-in 1;
    }

    &.is-closing {
        display: flex;
        animation: close .3s ease-in 1;
    }
}

.content {
    min-width: 50px;
    min-height: 50px;
    background: #fff;
    box-shadow:  0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

@media(max-width: 767px) {
    .content {
        width: 80%;

        &.custom-size {
            width: unset;
        }
    }

    .backdrop {
        &.mobile-fullscreen .content {
            width: 100%;
            height: 100%;
            & .content-holder {
                max-height: 100%;
                overflow-x: hidden;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
}

@keyframes show {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes close {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
