.container {
    transition: all 0.3s ease-in-out 0;
    text-align: center;
    font-size: 26px;
    position: relative;
    display: flex;
    justify-content: center;
}

.one,
.two,
.three {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: currentColor;
    animation: bouncedelay 1.2s infinite ease-in-out both;
}

.one {
    animation-delay: -.8s;
}

.two {
    animation-delay: -.4s;
}

@keyframes bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }

    40% {
      transform: scale(1);
    }
}
