.backdrop {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    z-index: 120;
    transition: all ease .3s;

    &.is-open {
        animation: backdrop-enter .3s linear 0s 1;
        display: block;
        opacity: 1;
    }

    &.is-closing {
        display: block;
        opacity: 0;
    }
}

@keyframes backdrop-enter {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.container {
    background: #fff;
    position: fixed;
    top: 0;
    right: -416px;
    width: 416px;
    max-width: 80%;
    height: 100%;
    max-height: 100%;
    z-index: 121;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 100vh;
    transition: all ease .3s;

    &.is-open {
        animation: container-enter .3s ease-in 0s 1;
        right: 0;
    }

    &.is-closing {
        right: -416px;
    }
}

@keyframes container-enter {
    from {
        right: -416px;
    }
    to {
        right: 0;
    }
}

.content {
    min-height: 100vh;
    display: flex;

    & > div {
        width: 100%;
    }
}
