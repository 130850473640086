.container {
    min-height: 72px;
    cursor: text;

    &.has-focus {
        .content {
            border-color: var(--color-blue);
        }
    }

    &.has-focus,
    &.has-value {
        .label {
            font-size: 10px;
            top: 8px;
            color: var(--color-text-base);
        }

        .prefix,
        .suffix {
            opacity: 1;
        }

        .input {
            opacity: 1;
        }
    }

    &.has-error {
        .content {
            border-color: var(--color-red);
        }

        .label {
            color: var(--color-red);
        }
    }

    &.theme-negative {
        .content {
            border-color: var(--color-white);
        }

        .label {
            color: var(--color-white);
        }

        .input {
            color: var(--color-white);

            &::placeholder {
                color: rgba(255, 255, 255, .7);
            }
        }

        &.has-error {
            .error {
                color: var(--color-white);
                font-style: italic;
            }
        }
    }

    &.disabled {
        opacity: .6;
        &, & * {
            pointer-events: none;
        }
    }

    &.readonly {
        cursor: default;

        .content {
            background: #f5f5f5;
        }

        .input {
            cursor: default;
        }
    }

    &.is-password {
        .icon {
            cursor: pointer;
            transition: opacity ease .2s;
            pointer-events: unset;

            &:hover {
                opacity: .8;
            }
        }
    }

    &.textarea {
        .content {
            height: unset;
            padding-bottom: 12px;
        }

        .input {
            height: 150px;
        }
    }

    &.icon-clickable {
        .icon {
            pointer-events: unset;
            cursor: pointer;
        }
    }
}

.content {
    border: 1px solid var(--color-grey-platinum);
    height: 56px;
    position: relative;
    text-align: left;
    background: var(--color-white);

    .content-holder {
        margin: 24px 0 0;
        padding: 0 12px 0;
        display: flex;
    }
}

.label {
    font-size: 14px;
    position: absolute;
    top: 18px;
    left: 12px;
    color: var(--color-grey-dark);
    pointer-events: none;
    transition: all ease .2s;
}

.prefix,
.suffix {
    opacity: 0;
    transition: all ease .2s;
}

.input {
    flex: 1;
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 16px;
    font-family: inherit;
    color: var(--color-grey-dark);
    letter-spacing: .4px;
    opacity: 0;
    transition: opacity ease .2s;
    background: transparent;
    text-overflow: ellipsis;

    &::placeholder {
        color: rgba(0, 0, 0, .3);
    }
}

.icon {
    position: absolute;
    top: 18px;
    right: 8px;
    width: 16px;
    height: 16px;
    color: var(--color-grey-mild);
    pointer-events: none;
}

.loading {
    position: absolute;
    top: 24px;
    right: 16px;
    color: var(--color-blue);
    pointer-events: none;
}

.error {
    font-size: 12px;
    color: var(--color-red);
    font-weight: bold;
    margin: 2px 0 0;
    height: 14px;
}

.container.labelless {
    min-height: 56px;

    &.textarea {
        .content {
            height: unset;
        }
    }

    .content {
        height: 40px;

        .label {
            display: none;
        }

        .content-holder {
            margin: 0;
            padding: 8px;

            .prefix {
                opacity: 1;
                font-size: 14px;
                padding: 11px 10px;
                margin: -10px 0 0 -8px;
                background: var(--color-grey-sand);
            }

            .suffix {
                opacity: 1;
                font-size: 14px;
                padding: 11px 10px;
                margin: -10px -8px 0 0;
                background: var(--color-grey-sand);
            }

            .input {
                opacity: 1;
            }

            .prefix + .input {
                margin: -10px 0 0 4px;
            }
        }
    }

    .icon {
        top: 12px;
    }

    .loading {
        top: 14px;
    }
}
