.admin {
    .admin-list {
        margin: 48px 0 0;
    
        .admin-list-item {
            &:not(:first-child) {
                margin: 8px 0 0;
            }
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
}

.list {
    .list-item {
        &:not(:first-child) {
            margin: 8px 0 0;
        }
    }
}

.details {
    display: flex;

    .details-cover {
        flex: 2;
    }

    .details-info {
        flex: 3;
        margin: 0 0 0 40px;

        div {
            &:nth-child(2) {
                margin: 16px 0 0;
            }

            &:nth-child(3) {
                margin: 60px 0 0;
            }

            &:nth-child(4) {
                margin: 16px 0 0;
            }

            &:nth-child(5) {
                margin: 94px 0 0;
            }
        }
    }
}
