.container {
    display: flex;
    cursor: pointer;
    color: var(--color-grey-dark);

    &.disabled {
        opacity: .5;
        cursor: default;
        pointer-events: none;
    }

    .icon {
        width: 24px;
    }

    .content {
        flex: 1;
    }
}
