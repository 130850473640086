.title {
    padding: 0 8px 0 0;
    background: rgb(47, 62, 82);
    color: var(--color-white);
    border-radius: 8px 8px 0 0;
    
    .title-main {
        display: flex;
        align-items: center;
    }

    .title-back {
        padding: 12px;
        cursor: pointer;
        transition: all ease .2s;
        border-radius: 8px 0 0 0;
        margin: 0 4px 0 0;

        &:hover {
            background: #fafafa33;
        }
    }

    .title-list {
        padding: 12px;
        flex: 1;
        display: flex;
    }

    .title-item {
        position: relative;
        transition: all ease .2s;

        &:last-child {
            font-weight: bold;
        }

        &:not(:last-child) {
            margin: 0 28px 0 0;

            &::after {
                content: '»';
                position: absolute;
                right: -20px;
                top: -1px;
                pointer-events: none;
            }
        }

        &.has-link {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .title-obs {
        margin: 8px 0 0;
        font-size: 14px;
        text-align: right;
    }
}
@media(max-width: 768px) {
    .title {
        .title-back {
            margin: 0 -16px 0 0;
        }

        .title-list {
            display: block;
        }

        .title-item {
            display: inline-flex;
        }
    }
}

.page {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow-light);

    &.is-refreshing {
        .content {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 100%; height: 100%;
                left: 0; top: 0;
                background: #fff9;
            }
        }
        
        &, & * {
            pointer-events: none;
            user-select: none;
        }
    }

    .static {
        margin: 0 0 24px;
    }

    .empty-ignore {
        margin: 0 0 24px;
    }

    .page-empty {
        font-style: italic;
        color: var(--color-grey-light);
    }

    .content {
        flex: 1;
        padding: 24px 16px;
        background: var(--color-white);
        border-radius: 0 0 8px 8px;
    }
}

.error-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .error-loading-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .error-loading-icon {
        width: 40px;
        color: var(--color-red);
    }

    .error-loading-title {
        margin: 16px 0 0;
        font-weight: bold;
    }

    .error-loading-text {
        margin: 8px 0 0;
        font-size: 14px;
    }

    .error-loading-button {
        margin: 16px 0 0;
        display: flex;
        justify-content: center;
    }
}
